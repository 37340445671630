import { Fragment, useCallback, useEffect, useState } from 'react';

// COMPONENTS
import { MaskedInput } from '../../../components/UI/MaskedInput';
import { Select } from '../../../components/UI/Select';
import { Button } from '../Button';

// UTILS
import { createLoanTypeOptions } from '../../../utils/createLoanTypeOptions';
import { counterFormat } from '../../../utils/format-counter';

// CONTEXT
import { useToast } from '../../../hooks/ToastContext';

// STYLES
import {
  Badge,
  BadgeContainer,
  Content,
  ContentItems,
  Count,
  FormContainer,
  LisItems,
  ParagraphListProposal,
  TitleItem,
  TitleListProposal,
  ValueContainer,
  ValueItem,
} from './styles';

// CONSTANTS
import { LoanTypeLabel } from '../../../constants/loan-types';

// ICONS
import { Delete, IconAdd } from './svg';

// SERVICES
import ProposalService from '../../../services/ProposalService';

// ENUMS
import { LoanType } from '../../../enums/loan-type.enum';
import { Status } from '../../../enums/proposal-status.enum';
import { useProposal } from '../../../hooks/AppContext';
import logger from '../../../utils/logger.utils';
import Proposal from '../../../interfaces/Proposal';

interface ITodo {
  loanType: string;
  proposalNumber: string;
  purchaseMargin: number;
  documentsProposal: any;
}

interface IProps {
  formValues: Proposal;
  onChange: any;
  loan: string;
  proposalNumber: string;
  purchaseMargin: number;
  handleChangeLoan: any;
  consigneesIdINSS: number[];
  handleChangeProposalNumber: any;
  handleChangePurchaseMargin: any;
}

const optionsLoan = createLoanTypeOptions();
const optionsLoanConsignedCard = createLoanTypeOptions([LoanType.NEW_LOAN, LoanType.LOAN_REFINANCE]);

const TodoLoan = ({
  formValues,
  onChange,
  loan,
  proposalNumber,
  purchaseMargin,
  consigneesIdINSS,
  handleChangeLoan,
  handleChangeProposalNumber,
  handleChangePurchaseMargin,
}: IProps) => {
  const { addToast } = useToast();
  const [todos, setTodos] = useState<ITodo[]>([]);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [externalproposalNumberError, setExternalproposalNumberError] = useState<string | null>(null);
  const [validationError, setValidationError] = useState({});
  const { validateAndExecuteMarginLimit } = useProposal();

  const hasConsignCard = formValues.hasConsignCard;

  const handleReturnError = useCallback(
    (name: string, err: string) => {
      setValidationError({
        ...validationError,
        [name]: err,
      });
    },
    [validationError],
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (loan !== '' && proposalNumber !== '') {
        const continueFunction = async () => {
          setIsLoading(true);
          let documentsProposal = [];
          try {
            const { data } = await ProposalService.getProposal(proposalNumber);
            documentsProposal = data.documentList;
          } catch (err) {}

          await addTodoLoan(loan, documentsProposal);
          handleChangeLoan({
            value: '',
          });
          handleChangeProposalNumber({ value: '' });
          handleChangePurchaseMargin({ value: 0 });
        };

        validateAndExecuteMarginLimit(continueFunction);
      } else {
        addToast({
          type: 'error',
          title: 'Obrigatório preencher o tipo de empréstimo e o número da proposta!',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Check if proposalNumer exists in array
  const status = formValues.loanDetails.some(function (e: any) {
    return e.proposalNumber == proposalNumber;
  });

  const addTodoLoan = (loanType: string, documentsProposal: any): void => {
    if (todos.length === 9) {
      logger.error('Error on the length of the number');
    } else if (status) {
      setExternalproposalNumberError('Número já utilizado');
    } else {
      const newTodos: ITodo[] = [...todos, { loanType, proposalNumber, purchaseMargin, documentsProposal }];
      setTodos(newTodos);
      formValues.loanDetails = newTodos;
      setIsLoading(false);
      addToast({
        type: 'success',
        title: 'Proposta adicionada com sucesso!',
      });
      onChange({
        name: 'loanDetails',
        value: newTodos,
      });
    }
  };

  const deleteTodoLoan = (index: number): void => {
    const newTodos = formValues.loanDetails;
    newTodos.splice(index, 1);
    setTodos(newTodos);
    onChange(formValues.loanDetails);
  };

  useEffect(() => {
    const invalid = todos.length === 9 || !(Object.values(validationError).find((el) => !!el) === undefined);
    setDisabledBtn(invalid || !!externalproposalNumberError);
  });

  const showPurchaseMargin = hasConsignCard && !consigneesIdINSS.includes(formValues.cnpjConsigneeId);

  return (
    <Fragment>
      <Content>
        <TitleListProposal>Insira as informações da proposta Sicred</TitleListProposal>
        <div>
          <form onSubmit={handleSubmit}>
            <FormContainer hasConsignCard={hasConsignCard}>
              <div className="selectLoan">
                <Select
                  id="loanType"
                  name="loanType"
                  label="Tipo de empréstimo"
                  placeholder="Selecione uma opção"
                  options={hasConsignCard ? optionsLoanConsignedCard : optionsLoan}
                  defaultValue={loan}
                  onChange={(value) => handleChangeLoan(value)}
                  value={optionsLoan.filter(({ value }) => value === loan)}
                />
              </div>
              <div className="inputNumberProposal">
                <MaskedInput
                  id="proposalNumber"
                  name="proposalNumber"
                  label="Número da proposta"
                  type="text"
                  onChange={(value) => {
                    setExternalproposalNumberError(null);
                    handleChangeProposalNumber(value);
                    setDisabledBtn(false);
                  }}
                  placeholder="0000000"
                  mask="9999999"
                  value={proposalNumber}
                  externalError={externalproposalNumberError}
                  hasError={handleReturnError}
                />
              </div>
              {showPurchaseMargin && (
                <div className="purchaseMargin">
                  <MaskedInput
                    id="purchaseMargin"
                    name="purchaseMargin"
                    label="Valor da margem para compras"
                    hasValidation
                    onChange={(value) => handleChangePurchaseMargin(value)}
                    value={purchaseMargin}
                    isDisabled={formValues.loanDetails.some(
                      (loanDetail: any) =>
                        loanDetail.purchaseMargin > 0 && formValues.status !== Status.PENDING_PURCHASE_MARGIN,
                    )}
                  />
                </div>
              )}
              <div className="button">
                <Button
                  primary
                  iconRight={IconAdd}
                  onClick={(e: any) => handleSubmit(e)}
                  {...{ disabled: disabledBtn || isLoading }}
                >
                  {' '}
                </Button>
              </div>
            </FormContainer>
          </form>
        </div>
        {formValues.loanDetails.length <= 0 ? (
          <ParagraphListProposal>
            Preencha os dados da proposta e clique no botão de ”+” para adicionar. Ao menos uma proposta precisar ser
            adicionada para continuar.
          </ParagraphListProposal>
        ) : (
          ''
        )}
      </Content>

      {formValues.loanDetails.map((todo: any, index: number) => {
        return (
          <Fragment key={index}>
            <LisItems>
              <ContentItems>
                <Count>{counterFormat(index + 1)}</Count>
                <ValueContainer>
                  <div>
                    <TitleItem>Tipo de empréstimo</TitleItem>
                    <ValueItem> {LoanTypeLabel[todo.loanType]}</ValueItem>
                  </div>
                </ValueContainer>
                <ValueContainer>
                  <div>
                    <TitleItem>Número da proposta</TitleItem>
                    <ValueItem>{todo.proposalNumber}</ValueItem>
                  </div>
                </ValueContainer>
                {hasConsignCard && (
                  <BadgeContainer>{todo.purchaseMargin > 0 && <Badge>Cartão vinculado</Badge>}</BadgeContainer>
                )}
                <button type="button" onClick={(): void => deleteTodoLoan(index)}>
                  <Delete />
                </button>
              </ContentItems>
            </LisItems>
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default TodoLoan;
