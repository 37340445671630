import React from 'react';

import { ContainerLabel, StyledCardContent, StyledTitleCard, AlignCheck, ContentAlign } from './styles';

interface CheckboxProps {
  id?: string;
  type: string;
  title: string;
  name: string;
  border?: boolean;
  big?: boolean;
  handleClick?: any;
  isChecked?: any;
  termId?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  type = 'checkbox',
  title,
  name,
  border,
  handleClick,
  isChecked,
  big,
  termId,
  ...props
}: any) => {
  return (
    <ContainerLabel {...{ border: border, big: big }} htmlFor={id}>
      <AlignCheck>
        <input
          id={id}
          name={name}
          type={type}
          onChange={handleClick}
          checked={isChecked}
          readOnly
          data-termId={termId}
          {...props}
        />
      </AlignCheck>

      <ContentAlign>
        <StyledCardContent>
          <StyledTitleCard>{title}</StyledTitleCard>
        </StyledCardContent>
      </ContentAlign>
    </ContainerLabel>
  );
};

export default Checkbox;
