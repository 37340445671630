// @ts-nocheck
import React, { useEffect, useState, ReactElement, useCallback } from 'react';

import {
  ContentSteps,
  HeaderSteps,
  TitleSteps,
  ContentProposal,
  ModalAction,
  ActionSignProposal,
  LinkDownload,
} from '../styles';

// CONTEXT
import { useSignature } from '../../../../hooks/SignatureContext';

// SERVICE
import SignatureService from '../../../../services/SignatureService';

//SVG
import { ArrowLeft, IconDownload } from '../../Form/svgs';

// COMPONENTS
import { ProposalConsig, ProposalFgts } from '../../Proposal';
import { Modal } from '../../../../components/UISignatue/Modal';
import { Button } from '../../../../components/UISignatue/Button';
import { Checkbox } from '../../../../components/UISignatue/Checkbox';
import { Loader } from '../../../../components/UISignatue/Loader';

import { ArrowRight } from '../svgs';
import Signature from '../../../../interfaces/Signature';
import logger from '../../../../utils/logger.utils';
import { Type } from '../../../../enums/loan-type.enum';
import ProposalService from '../../../../services/ProposalService';

interface IProposaList {
  formValues: Signature;
}

export const ProposaList = ({ formValues }: IProposaList) => {
  const {
    formOpened,
    setFormOpened,
    showModal,
    setShowModal,
    setStep,
    nextStepDisabled,
    setNextStepDisabled,
    loading,
    setTermId,
    nextStep,
  } = useSignature();
  const [data, setData] = useState<any | null>([]);
  const [fetchloading, setFetchLoading] = useState(false);
  const [terms, setTerms] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any | null>([]);

  const handleListProposal = async () => {
    try {
      setFetchLoading(true);
      const proposalResponse = await SignatureService.fetchProposal(formOpened.token);
      setData(proposalResponse.data);
      await fetchAndProcessTerms(proposalResponse.data);
    } catch (error) {
      logger.error('Error handling list proposal');
    } finally {
      setFetchLoading(false);
    }
  };

  const handleNextstepModal = useCallback(
    (e: any) => {
      formValues.proposals = isCheck;
      setFormOpened(formValues);
      setShowModal(false);
      sendAcceptDraftContract(e, formValues);
    },
    [formValues],
  );

  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(
      data.map((li: any) => {
        setTermId(li.benefitCardTermId);
        return li.proposalNumber;
      }),
    );

    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e: any) => {
    const { id, checked, dataset } = e.target;
    if (dataset.termid && checked) {
      setTermId(dataset.termid);
    } else {
      setTermId('');
    }
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id));
    }
  };

  const sendAcceptDraftContract = async (e: any, formValues: Signature) => {
    try {
      setFetchLoading(true);
      const fetchProposalSignature = await SignatureService.acceptDraftContract(formValues);
      const { status } = fetchProposalSignature;

      if (status !== 204) {
        throw new Error('Error Signature');
      }

      nextStep(e);
    } catch (err) {
      logger.error('Error fetching proposal signature');
      setErrorSignature(true);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleSignedProposalConsig = (e) => {
    if (isCheck.length === data.length) {
      formValues.proposals = isCheck;
      setFormOpened(formValues);
      sendAcceptDraftContract(e, formValues);
    } else {
      setShowModal(true);
    }
  };

  const handleSignedProposalFgts = (e) => {
    formValues.proposals = [data[0].proposalNumber];
    setFormOpened(formValues);
    sendAcceptDraftContract(e, formValues);
  };

  const handlePrevstep = () => {
    setStep((previousStep: any) => previousStep - 1);
  };

  useEffect(() => {
    const isNextStepDisabled = !(formOpened.type === Type.FGTS || isCheck.length >= 1);
    setNextStepDisabled(isNextStepDisabled);
  }, [isCheck]);

  useEffect(() => {
    handleListProposal();
  }, []);

  const fetchAndProcessTerms = async (proposals: any) => {
    const termRequests = new Map();

    proposals.forEach((proposal: any) => {
      if (proposal.benefitCardTermId) {
        const key = proposal.benefitCardTermId;
        if (!termRequests.has(key)) {
          termRequests.set(key, SignatureService.fetchTermsWithId(proposal.benefitCardTermId));
        }
      }

      proposal.terms.forEach((termId: string) => {
        if (
          termId === 'PAYROLL_LOAN_INSS' ||
          termId === 'PAYROLL_LOAN_PORTABILITY' ||
          termId === 'PAYROLL_LOAN_CREDITLIFEINSURANCE'
        ) {
          const key = `${termId}_${proposal.proposalNumber}`;
          if (!termRequests.has(key)) {
            termRequests.set(key, SignatureService.conditionsAndClauses(termId, proposal.proposalNumber));
          }
        } else {
          const key = termId;
          if (!termRequests.has(key)) {
            termRequests.set(key, SignatureService.conditionsAndClauses(termId));
          }
        }
      });
    });

    const termResponses = await Promise.all(termRequests.values());

    const mapTermToViewModel = (termData: any) => ({
      type: termData.id,
      label: termData.name,
      pdf: termData.url,
      html: termData.html,
    });

    const processedTerms = [];
    termResponses.forEach(({ data }) => {
      if (Array.isArray(data)) {
        data.forEach((term) => processedTerms.push(mapTermToViewModel(term)));
      } else {
        processedTerms.push(mapTermToViewModel(data));
      }
    });

    setTerms(processedTerms);
    formValues.terms = processedTerms.map((term) => term.type);
    setFormOpened(formValues);
  };

  const consigneesIdINSS = [18, 19];
  const showEmailForInvoice = data.some((item: any) => consigneesIdINSS.includes(item.cnpjConsigneeId));

  return (
    <ContentSteps>
      <HeaderSteps>
        <button onClick={handlePrevstep}>
          <ArrowLeft />
        </button>
        <TitleSteps>Minhas propostas</TitleSteps>
      </HeaderSteps>

      <ContentProposal>
        {fetchloading ? (
          <>
            <Loader black title="Carregando propostas" />
          </>
        ) : (
          <>
            {formOpened.type === Type.FGTS ? (
              <>
                {data.map((item: any, id: any) => {
                  return <ProposalFgts formValues={item} key={id} />;
                })}
              </>
            ) : (
              <>
                <Checkbox
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                  title={'Selecionar todas as propostas' + ` (${data.length})`}
                  border
                  big
                />
                {data.map((item: any, id: any) => {
                  return (
                    <ProposalConsig
                      formValues={item}
                      key={id}
                      id={item.proposalNumber}
                      handleClick={handleClick}
                      isChecked={isCheck.includes(item.proposalNumber)}
                    />
                  );
                })}
              </>
            )}
            {showEmailForInvoice && (
              // Campo de recebimento da fatura via e-mail
              // CAMPO VISUAL PARA O USUARIO, NÃO PODE SER ALTERADO E NÃO ENVIA NENHUMA INFORMAÇÃO.
              <div style={{ marginTop: '20px' }}>
                <Checkbox
                  type="checkbox"
                  name="email"
                  id="email"
                  isChecked
                  title={'Recebimento da fatura via e-mail'}
                  border
                  disabled
                />
              </div>
            )}

            {terms.map((term, key) => (
              <LinkDownload key={`Conditions_Clauses_${key}`}>
                <IconDownload />
                <a download href={term.pdf}>
                  {term.label}
                </a>
              </LinkDownload>
            ))}
          </>
        )}
      </ContentProposal>

      {/* Modal */}
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        title={'Você não selecionou todas as propostas'}
        description={
          'Você selecionou apenas ' +
          ` (${isCheck.length})` +
          ' proposta de um total de ' +
          ` (${data.length})` +
          '. Se você esqueceu de selecionar alguma proposta, é só clicar em voltar. Caso você tenha selecionado corretamente, é só clicar em prosseguir.'
        }
      >
        <ModalAction>
          <Button black big spaced onClick={() => setShowModal(false)} iconRight={ArrowRight}>
            Voltar
          </Button>
          <Button transparent big spaced onClick={handleNextstepModal}>
            Prosseguir assim mesmo
          </Button>
        </ModalAction>
      </Modal>
      {/* IF PROPOSAL ACTION */}
      {formOpened.type === Type.FGTS ? (
        <ActionSignProposal>
          <Button
            black
            big
            spaced
            onClick={handleSignedProposalFgts}
            iconRight={ArrowRight}
            {...{ disabled: fetchloading }}
          >
            Assinar proposta
          </Button>
        </ActionSignProposal>
      ) : (
        <ActionSignProposal>
          <Button
            black
            big
            spaced
            onClick={handleSignedProposalConsig}
            iconRight={ArrowRight}
            {...{ disabled: nextStepDisabled || loading || fetchloading }}
          >
            Assinar propostas ({isCheck.length} de {data.length})
          </Button>
        </ActionSignProposal>
      )}
    </ContentSteps>
  );
};
